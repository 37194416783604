import React from 'react'
import './index.module.scss'

function KnowledgeGraph() {
  return [
    <div styleName="container" key="KnowledgeGraph" id="knowledgeGraph">
      <h3 styleName="title">知识图谱</h3>
      <div styleName="sub-title">
        基于超大规模数据网络体系，对实体、关键词的深度解析，支持多维多场景应用
      </div>
      <div styleName="content" className="clearfix">
        <ul styleName="types">
          <li styleName="type-item">医疗</li>
          <li styleName="type-item">养生</li>
          <li styleName="type-item">菜谱</li>
          <li styleName="type-item">诗词</li>
          <li styleName="type-item">学术</li>
          <li styleName="type-item">教育</li>
          <li styleName="type-item">地理</li>
          <li styleName="type-item">人物</li>
          <li styleName="type-item">文娱</li>
          <li styleName="type-item">美妆</li>
          <li styleName="type-item">体育</li>
          <li styleName="type-item">……</li>
        </ul>
        <img styleName="brain-img" src={require('./img/f-8-1.png')} alt="" />
      </div>
    </div>
  ]
}

export default KnowledgeGraph
