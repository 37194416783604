import React from 'react'
import './index.module.scss'

function ComputerVision() {
  const services = [
    {
      name: '人脸算法',
      icon: require('./img/f-4-1.png'),
      link: 'https://cnbj6.cloud.mi.com/#/index/product/face-recognition-algorithm',
    },
    {
      name: '人体算法',
      icon: require('./img/f-4-2.png'),
      link: null,
    },
    {
      name: '图像识别',
      icon: require('./img/f-4-3.png'),
      link: 'https://cnbj6.cloud.mi.com/#/index/product/image-identification',
    },
    {
      name: '图像理解',
      icon: require('./img/f-4-4.png'),
      link: null,
    },
    {
      name: 'OCR',
      icon: require('./img/f-4-5.png'),
      link: null,
    },
  ]
  return [
    <div styleName="container" key="ComputerVision" id="computerVision">
      <h3 styleName="title">计算机视觉</h3>
      <div styleName="sub-title">
        基于深度学习技术，提供行业领先的视觉算法，一起探索智慧“视”界
      </div>
      <ul styleName="services">
        {services.map((item, index) => (
          <li key={index} styleName="details-item">
            {item.link !== null
              ? <a href={item.link}>
                <img src={item.icon} alt="" />
                <span>{item.name}</span>
              </a>
              : <div>
                <img src={item.icon} alt="" />
                <span>{item.name}</span>
              </div>
            }
          </li>
        ))}
      </ul>
    </div>
  ]
}

export default ComputerVision
