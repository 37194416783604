import React from 'react'
import Logo from '../Logo'
import './index.module.scss'

function Navigation() {
  return [
    <div styleName="container" key="navigation">
      <div styleName="navigation">
        <Logo />
      </div>
    </div>
  ]
}

export default Navigation
