import React from 'react'
import logoImg from './img/logo.png'
import './index.module.scss'

const Logo = () => {
  return (
    <a styleName="brand" href="/" style={{ textDecoration: 'none' }}>
      <img src={logoImg} alt="小米AI开放平台" />
      <span styleName="logo-title">小米AI开放平台</span>
    </a>
  )
}

export default Logo
