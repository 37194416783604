import React from 'react'
import './index.module.scss'

function Acoustics() {
  const services = [
    {
      name: '波束形成',
      icon: require('./img/f-5-1.png')
    },
    {
      name: '声源定位',
      icon: require('./img/f-5-2.png')
    },
    {
      name: '去混响',
      icon: require('./img/f-5-3.png')
    },
    {
      name: '降噪',
      icon: require('./img/f-5-4.png')
    },
    {
      name: '回声消除',
      icon: require('./img/f-5-5.png')
    },
    {
      name: '分布式拾音',
      icon: require('./img/f-5-6.png')
    },
  ]
  return [
    <div styleName="container" key="Acoustics" id="acoustics">
      <h3 styleName="title">声学</h3>
      <div styleName="sub-title">
        拥有国内领先的声学技术，支持高准确度分布式就近唤醒，提供行业公认的声学测试认证
      </div>
      <ul styleName="services">
        {services.map((item, index) => (
          <li key={index} styleName="details-item">
            <img src={item.icon} alt="" />
            <span>{item.name}</span>
          </li>
        ))}
      </ul>
    </div>
  ]
}

export default Acoustics
