import React from 'react'
import './index.module.scss'

function IQA() {
  const services = [
    {
      name: '智能场景',
      icon: require('./img/f-9-1.png')
    },
    {
      name: '个性化回复',
      icon: require('./img/f-9-2.png')
    },
    {
      name: '多轮交互',
      icon: require('./img/f-9-3.png')
    },
    {
      name: '情绪识别',
      icon: require('./img/f-9-4.png')
    },
  ]
  return [
    <div styleName="container" key="IQA" id="iqa">
      <h3 styleName="title">智能问答</h3>
      <div styleName="sub-title">
        按需定制，全天候在线，多渠道汇聚且可视化数据分析，全面高效提升服务响应与业务洞察力
      </div>
      <ul styleName="services" className="clearfix">
        {services.map((item, index) => (
          <li key={index} styleName="details-item">
            <img src={item.icon} alt="" />
            <span>{item.name}</span>
          </li>
        ))}
      </ul>
    </div>
  ]
}

export default IQA
