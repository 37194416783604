import React from 'react'
import './index.module.scss'

function Services() {
  const services = [
    {
      id: 'deeplearning',
      name: '深度学习',
      icon: require('./img/f-2-1.png')
    },
    {
      id: 'computerVision',
      name: '计算机视觉',
      icon: require('./img/f-2-2.png')
    },
    {
      id: 'acoustics',
      name: '声学',
      icon: require('./img/f-2-3.png')
    },
    {
      id: 'voice',
      name: '语音',
      icon: require('./img/f-2-4.png')
    },
    {
      id: 'nlp',
      name: '自然语言处理',
      icon: require('./img/f-2-5.png')
    },
    {
      id: 'knowledgeGraph',
      name: '知识图谱',
      icon: require('./img/f-2-6.png')
    },
    {
      id: 'iqa',
      name: '智能问答',
      icon: require('./img/f-2-7.png')
    },
    {
      id: 'xiaoai',
      name: '小爱同学',
      icon: require('./img/f-2-8.png')
    },
  ]
  function scrollTo(id) {
    const element = document.getElementById(id)
    element.scrollIntoView()
  }
  return [
    <ul styleName="container" key="services">
      {services.map((service, index) => (
        <li key={index} styleName="item">
          <a onClick={() => { scrollTo(service.id) }}>
            <div styleName="icon">
              <img src={service.icon} alt="" />
            </div>
            <div styleName="name">{service.name}</div>
          </a>
        </li>
      ))}
    </ul>
  ]
}

export default Services
