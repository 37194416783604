import React from 'react'
import './index.module.scss'

function NLP() {
  const services = [
    {
      name: ['语言处理基础', '技术'],
      icon: require('./img/f-7-1.png'),
      link: null,
    },
    {
      name: ['文本审核'],
      icon: require('./img/f-7-2.png'),
      link: null,
    },
    {
      name: ['情感分析'],
      icon: require('./img/f-7-3.png'),
      link: null,
    },
    {
      name: ['机器翻译'],
      icon: require('./img/f-7-4.png'),
      link: 'https://cnbj6.cloud.mi.com/#/index/product/machine-translation',
    },
    {
      name: ['智能聊天'],
      icon: require('./img/f-7-5.png'),
      link: null,
    },
  ]
  return [
    <div styleName="container" key="NLP" id="nlp">
      <h3 styleName="title">自然语言处理</h3>
      <div styleName="sub-title">
        基于小米大数据和国际领先的自然语言处理技术，全面覆盖语言理解各种场景，完美理解世界
      </div>
      <ul styleName="services">
        {services.map((item, index) => (
          <li key={index} styleName="details-item">
            {item.link !== null
              ? <a href={item.link}>
                <img src={item.icon} alt="" />
                {item.name.map((txt, index) => (
                  <span key={index}>{txt}</span>
                ))}
              </a>
              : <div>
                <img src={item.icon} alt="" />
                {item.name.map((txt, index) => (
                  <span key={index}>{txt}</span>
                ))}
              </div>
            }
          </li>
        ))}
      </ul>
    </div>
  ]
}

export default NLP
