import React from 'react'
import './index.module.scss'

function Voice() {
  const services = [
    {
      name: '语音识别',
      icon: require('./img/f-6-1.png')
    },
    {
      name: '语音唤醒',
      icon: require('./img/f-6-2.png')
    },
    {
      name: '语音合成',
      icon: require('./img/f-6-3.png')
    },
    {
      name: '声纹识别',
      icon: require('./img/f-6-4.png')
    },
  ]
  return [
    <div styleName="container" key="Voice" id="voice">
      <h3 styleName="title">语音</h3>
      <div styleName="sub-title">
        业内领先的语音技术为你提供高灵敏语音唤醒，多场景高精度的语音识别，支持男女儿童准确识别
      </div>
      <ul styleName="services">
        {services.map((item, index) => (
          <li key={index} styleName="details-item">
            <img src={item.icon} alt="" />
            <span>{item.name}</span>
          </li>
        ))}
      </ul>
    </div>
  ]
}

export default Voice
