import React from 'react'
import './index.module.scss'

function DeepLearning() {
  return [
    <div styleName="container" key="navigation" id="deeplearning">
      <h3 styleName="title">深度学习</h3>
      <div styleName="sub-title">端云俱智，双面加速</div>
      <div styleName="content">
        <div styleName="details-item">
          <img src={require('./img/ml.png')} alt="" />
          <span styleName="item-intro">优质高效的深度学习云服务平台</span>
          <div>
            <div styleName="feats-item">
              <span>一站式自动化</span>
              <span>流水线运行</span>
            </div>
            <div styleName="feats-item">
              <span>提供完整云端</span>
              <span>开发环境</span>
            </div>
            <div styleName="feats-item">
              <span>支持所有常用</span>
              <span>开源框架</span>
            </div>
          </div>
          <div styleName="item-action">
            <a href="https://cnbj6.cloud.mi.com/#/index/product/cloud-ml" target="_blank">了解详情 &gt;</a>
          </div>
        </div>
        <div styleName="details-item">
          <img src={require('./img/mace.png')} alt="" />
          <span styleName="item-intro">打造业界最好的移动端深度学习框架</span>
          <div>
            <div styleName="feats-item">
              <span>支持异构计算加速</span>
              <span>(CPU/GPU/DSP)</span>
            </div>
            <div styleName="feats-item">
              <span>支持TensorFlow/</span>
              <span>Caffe模型</span>
            </div>
            <div styleName="feats-item">
              <span>支持高通/MTK/</span>
              <span>澎湃等芯片</span>
            </div>
          </div>
          <div styleName="item-action">
            <a href="https://github.com/XiaoMi/mace" target="_blank">了解详情 &gt;</a>
          </div>
        </div>
      </div>
    </div>
  ]
}

export default DeepLearning
