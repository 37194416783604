import React from 'react'
import Navigation from './components/Navigation'
// import { connect } from 'dva'
import './index.module.scss'
import Services from './components/Services'
import DeepLearning from './components/DeepLearning'
import ComputerVision from './components/ComputerVision'
import Acoustics from './components/Acoustics'
import Voice from './components/Voice'
import NLPcomponent from './components/NLP'
import KnowledgeGraph from './components/KnowledgeGraph'
import IQAcomponent from './components/IQA'
import XiaoAi from './components/XiaoAi'
import Footer from './components/Footer'

function IndexPage() {
  return [
    <Navigation key="nav" />,
    <div key="content">
      <div styleName="banner1"></div>
      <Services />
      <DeepLearning />
      <ComputerVision />
      <Acoustics />
      <Voice />
      <NLPcomponent />
      <KnowledgeGraph />
      <IQAcomponent />
      <XiaoAi />
      <Footer />
    </div>
  ]
}

export default IndexPage
